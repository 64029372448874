import React from "react"
import { graphql } from "gatsby"
import {Header} from 'semantic-ui-react'

import Layout from "../components/Layout"
import CategoryList from "../components/CategoryList"
import SEO from "../components/SEO"

export const query = graphql`
  query {
    allStrapiCategory {
      edges {
        node {
          strapiId
          Name
          Image {
            childImageSharp {
              sizes(maxWidth: 250) {
                ...GatsbyImageSharpSizes
              }
            }
          }                  
        }
      }
    }
  }
`

const IndexPage = ({ data, location }) => {
  const categories = data.allStrapiCategory.edges

  return (
    <Layout location={location}>
      <SEO title={'Home'} />
      <Header
        as="h3"
        icon
        textAlign="center"
        style={{
          marginBottom: '2em',
        }}
      >
        <Header.Content
          style={{
            width: '60%',
            margin: '0 auto',
          }}
        >
        </Header.Content>
      </Header>
      <CategoryList categories={categories} />
    </Layout>
  )
}

export default IndexPage
