import React from 'react'
import {Card} from 'semantic-ui-react'
import Img from 'gatsby-image'
import {Link} from 'gatsby'

const mapCategories = categories =>
categories.map(({node: {strapiId, Name, Image}}) => {
    return {
      as: Link,
      to: `/category/${strapiId}/`,
      childKey: strapiId,
      image: <Img sizes={Image.childImageSharp.sizes} alt={Name} />,
      header: Name,
    }
  }
)


const CategoryList = ({ categories }) => {
  const items = mapCategories(categories)
  return (
    <Card.Group items={items} itemsPerRow={2} stackable />
  )
}

export default CategoryList
